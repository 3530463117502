import * as React from "react";

export const AudioContext = React.createContext();

export const AudioProvider = ({ children }) => {
  const audioRef = React.useRef(null);

  const handlePlay = () => {
    // Access the audio element and play it
    if (audioRef.current) {
      audioRef.current.play();
    }
  };

  const handlePause = () => {
    // Access the audio element and pause it
    if (audioRef.current) {
      audioRef.current.pause();
    }
  };

  const handleVolumeChange = (event) => {
    // Set the volume of the audio element
    if (audioRef.current) {
      audioRef.current.volume = event.target.value;
    }
  };

  const handleStop = (event) => {
    // Set the volume of the audio element
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
  };

  return (
    <AudioContext.Provider
      value={{
        audioRef,
        handlePause,
        handlePlay,
        handleStop,
        handleVolumeChange,
      }}
    >
      <audio ref={audioRef} src="/audio/Radio.mp3" />
      {children}
    </AudioContext.Provider>
  );
};
