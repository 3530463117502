import "./src/styles/global.css";
import React from "react";
import { AudioProvider } from "./src/components/audiocontext";
import { DesktopRadio, MobileRadio, Radio } from "./src/components/radio";

export const wrapRootElement = ({ element }) => {
  return <AudioProvider>{element}</AudioProvider>;
};

export const wrapPageElement = ({ element, props }) => (
  <>
    <DesktopRadio />
    <MobileRadio />
    {element}
  </>
);
