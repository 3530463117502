import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { AudioContext } from "./audiocontext";
import { useMediaQuery } from "react-responsive";

export const MobileRadio = () => {
  const { handlePause, handlePlay, handleStop } =
    React.useContext(AudioContext);

  return (
    <div className="fixed bottom-0 h-24 z-50 w-full p-2 items-center bg-gradient-to-r from-blue-400 to-indigo-700 md:hidden flex justify-around">
      <StaticImage className="w-auto h-auto" src="../images/ui/Radio.png" />
      <div className="flex flex-col items-center justify-center">
        <div className="font-sans text-shadow text-white font-bold leading-5  text-center">
          Saturdays with Clarence Fletcher
        </div>
        <div className=" text-sm text-white text-center font-sans">
          Clarence Fletcher
        </div>
      </div>
      <div className="flex flex-col items-center">
        <StaticImage className="w-16 mb-2" src="../images/ui/rerun.png" />

        <div className="flex flex-row px-2 justify-between items-center">
          <button onClick={handlePause}>
            <StaticImage
              className="w-10"
              src="../images/ui/Playback button L.png"
            />
          </button>
          <button onClick={handlePlay}>
            <StaticImage
              className="w-10"
              src="../images/ui/Playback button main.png"
            />
          </button>
          <button onClick={handleStop}>
            <StaticImage
              className="w-10"
              src="../images/ui/Playback button R.png"
            />
          </button>
        </div>
      </div>
    </div>
  );
};

export const DesktopRadio = () => {
  const { handlePause, handlePlay, handleStop, handleVolumeChange } =
    React.useContext(AudioContext);

  const isMobileOrTablet = useMediaQuery({ query: "(max-width: 1224px)" });

  if (isMobileOrTablet) {
    return "";
  }

  return (
    <div className="fixed z-50 p-2 py-3 justify-between top-64 mt-2 right-4 h-56 w-40 shadow-2xl bg-gradient-to-b from-blue-400 to-indigo-700  rounded-2xl flex flex-col">
      <div className="flex flex-row justify-between items-center">
        <StaticImage className="w-12" src="../images/ui/Radio.png" />
        <StaticImage className="w-16" src="../images/ui/rerun.png" />
      </div>
      <div>
        <div className="font-sans text-shadow text-white font-bold leading-5 mb-3 text-center">
          Saturdays with Clarence Fletcher
        </div>
        <div className=" text-sm text-white text-center font-sans">
          Clarence Fletcher
        </div>
      </div>
      <div className="flex flex-row px-2 justify-between items-center">
        <button onClick={handlePause}>
          <StaticImage
            className="w-10"
            src="../images/ui/Playback button L.png"
          />
        </button>
        <button onClick={handlePlay}>
          <StaticImage
            className="w-10"
            src="../images/ui/Playback button main.png"
          />
        </button>
        <button onClick={handleStop}>
          <StaticImage
            className="w-10"
            src="../images/ui/Playback button R.png"
          />
        </button>
      </div>
      <input
        type="range"
        min="0"
        max="1"
        step="0.1"
        onChange={handleVolumeChange}
      />
      {/* <StaticImage
            className="w-12"
            src="../images/ui/Playback volume bar.png"
          />
          <StaticImage
            className="w-12"
            src="../images/ui/Playback volume handle.png"
          /> */}
    </div>
  );
};
