exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-communitynewsletter-js": () => import("./../../../src/pages/communitynewsletter.js" /* webpackChunkName: "component---src-pages-communitynewsletter-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-council-js": () => import("./../../../src/pages/council.js" /* webpackChunkName: "component---src-pages-council-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lighthouse-js": () => import("./../../../src/pages/lighthouse.js" /* webpackChunkName: "component---src-pages-lighthouse-js" */),
  "component---src-pages-parish-js": () => import("./../../../src/pages/parish.js" /* webpackChunkName: "component---src-pages-parish-js" */),
  "component---src-pages-subscribe-js": () => import("./../../../src/pages/subscribe.js" /* webpackChunkName: "component---src-pages-subscribe-js" */),
  "component---src-pages-thankyou-js": () => import("./../../../src/pages/thankyou.js" /* webpackChunkName: "component---src-pages-thankyou-js" */),
  "component---src-pages-unsubscribe-js": () => import("./../../../src/pages/unsubscribe.js" /* webpackChunkName: "component---src-pages-unsubscribe-js" */)
}

